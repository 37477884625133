


























































































import { Component, Vue, Watch } from 'vue-property-decorator'
// eslint-disable-next-line no-unused-vars
import { Form as ElForm } from 'element-ui'
import axios from 'axios'
import { UserModule } from '@/store/modules/user'

@Component({
  name: 'Login'
})
export default class extends Vue {
  private loginForm = {
    username: '',
    password: ''
  }

  private loginRules = {
  }

  private passwordType = 'password'
  private loading = false
  private redirect
  private otherQuery = {}

  @Watch('$route', { immediate: true })
  private onRouteChange(route) {
    const query = route.query
    if (query) {
      this.redirect = query.redirect
      this.otherQuery = this.getOtherQuery(query)
    }
  }

  private handleLogin() {
    (this.$refs.loginForm as ElForm).validate(async(valid) => {
      if (valid) {
        // UserModule.SET_TOKEN({
        //   // @ts-ignore
        //   token: '11111',
        //   // @ts-ignore
        //   tokenValidTime: '600'
        // })
        // this.$router.push({
        //   path: this.redirect || '/',
        //   query: this.otherQuery
        // })
        this.loading = true
        axios({
          url: `${process.env.VUE_APP_BASE_API}loginDP`,
          method: 'post',
          data: { username: this.loginForm.username, password: this.loginForm.password, code: '4', uuid: '1111' }
        }).then((data) => {
          // @ts-ignore
          if (data.data.code !== 200) {
            this.$message.error('登录失败！')
          } else {
            UserModule.SET_TOKEN({
              // @ts-ignore
              token: data.data.token,
              // @ts-ignore
              tokenValidTime: data.data.tokenValidTime
            })

            UserModule.SET_USER({
              username: this.loginForm.username,
              pwd: this.loginForm.password
            })

            this.$message.success('登录成功！')
            this.$router.push({
              path: this.redirect || '/',
              query: this.otherQuery
            })
          }
        }, () => {
          this.$message.error('登录失败！')
        })
          .finally(() => {
            this.loading = false
          })
      }
    })
  }

  private getOtherQuery(query) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== 'redirect') {
        acc[cur] = query[cur]
      }
      return acc
    }, {})
  }
}
